@import "../../global.scss";

.navigation {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: 100%;
  background: $secondColor;
  box-shadow: -5px -5px 20px #393c41, 5px 5px 20px rgb(20, 20, 20);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: visible;

  ul {
    position: relative;
    display: flex;
    width: 100%;
    overflow: visible;
    padding: 0;

    li {
      position: relative;
      list-style: none;
      width: 20%;
      height: 100%;
      z-index: 1;
      overflow: visible;

      a {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        text-align: center;
        overflow: visible;

        .icon {
          position: relative;
          display: block;
          line-height: 75px;
          font-size: 1.5em;
          text-align: center;
          transition: 0.5s;
          color: $mainColor;
        }

        .text {
          position: absolute;
          color: $mainColor;
          font-weight: 400;
          font-size: 0.75em;
          letter-spacing: 0.05em;
          transition: 0.5s;
          opacity: 0;
          transform: translateY(20px);
        }
      }
    }
  }
}

.navigation ul li.active a .icon {
  transform: translateY(-35px);
}

.navigation ul li.active a .text {
  opacity: 1;
  transform: translateY(10px);
}

.indicatorDiv {
  position: absolute;
  width: 20%;
  height: 60px;
  overflow: visible;
  transition: 0.5s;

  .indicator {
    display: flex;
    position: relative;
    border-radius: 50%;
    border: 6px solid $secondColor;
    top: -30%;
    width: 50px;
    height: 50px;
    background: $accentColor;
    overflow: visible;
    margin: auto;
    box-shadow: -5px -5px 20px #393c41, 5px 5px 20px rgb(20, 20, 20);
    // box-shadow: -2px -5px 7px #363b44, -2px 5px 7px #14171a;
  }
}

.navigation ul li:nth-child(1).active ~ .indicatorDiv {
  transform: translateX(calc(0));
}

.navigation ul li:nth-child(2).active ~ .indicatorDiv {
  transform: translateX(calc(100%));
}

.navigation ul li:nth-child(3).active ~ .indicatorDiv {
  transform: translateX(calc(200%));
}

.navigation ul li:nth-child(4).active ~ .indicatorDiv {
  transform: translateX(calc(300%));
}

.navigation ul li:nth-child(5).active ~ .indicatorDiv {
  transform: translateX(calc(400%));
}

@media screen and (max-width: 500px) {
  .navigation {
    width: 95%;
  }
}
