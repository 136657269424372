@import "../../global.scss";

.formation {
  justify-content: center;
  text-align: center;
  .diplomes {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    padding-right: 9.5%;
    height: 75%;
    cursor: grab;

    .diplome {
      margin-top: 20px;
      margin-left: 9.5%;
      max-height: 60vh;
      min-width: 300px;
      max-width: 350px;
      background-color: $secondColor;
      color: $mainColor;
      border-radius: 20px;
      box-shadow: -2px -5px 20px #363b44, 0px 5px 20px #14171a;
      padding-top: 70px;
      padding-bottom: 70px;
      padding: 30px;
      position: relative;

      .logo {
        height: 50px;
        width: 50px;
        border-radius: 10px;
      }

      h3 {
        position: absolute;
        margin-top: 10px;
      }

      .title {
        color: $accentColor;
      }
      .certif {
        width: 60%;
        position: absolute;
        top: 45%;
        padding: 0px;
        border-radius: 10px;
        left: 60px;
        box-shadow: -2px -5px 7px #363b44, -2px 5px 7px #14171a;
      }
      .btnContainer {
        justify-content: center;
        display: flex;

        .detailsBtn {
          position: absolute;
          bottom: 25px;
          box-shadow: -2px -5px 7px #363b44, -2px 5px 7px #14171a;
          border-radius: 50px;
          button {
            border: none;
            background-color: $accentColor;
          }
        }
      }
    }
  }
}

.swipeDiv {
  width: 100%;
  height: 10%;

  @keyframes swipe {
    from {
      transform: rotate(35deg);
    }
    to {
      transform: rotate(-35deg);
    }
  }
  .swipeIcon {
    position: relative;
    transform: scale(1.3);
    color: $mainColor;
    animation: swipe 1s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }
}

@media (min-width: 1200px) {
  #formation .swipeDiv {
    display: none;
  }
  .diplomes {
    cursor: default !important;
  }
  .formation .diplomes {
    justify-content: center;
  }
}
