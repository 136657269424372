@import "../../global.scss";

.experience {
  background-color: $secondColor;
  justify-content: center;
  text-align: center;

  .jobs {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    height: 75%;
    padding-right: 9.5%;
    cursor: grab;

    .job {
      margin-top: 20px;
      margin-left: 9.5%;
      max-height: 60vh;
      min-width: 300px;
      max-width: 350px;
      background-color: $secondColor;
      color: $mainColor;
      border-radius: 20px;
      box-shadow: -2px -5px 20px #363b44, 0px 5px 20px #14171a;
      padding-top: 70px;
      padding-bottom: 70px;
      padding: 30px;
      position: relative;

      .title {
        color: $accentColor;
      }

      h3 {
        white-space: nowrap;
        overflow: initial;
        position: absolute;
        margin-top: 10px;
      }

      .logo {
        height: 50px;
        width: 50px;
        border-radius: 10px;
      }

      .btnContainer {
        justify-content: center;
        display: flex;

        .detailsBtn {
          background-color: $accentColor;
          border: none;
          border-radius: 50px;
          box-shadow: -2px -5px 7px #363b44, -2px 5px 7px #14171a;
          position: absolute;
          bottom: 25px;
        }
      }

      div ul {
        padding: 0;
      }
    }
  }
  ::-webkit-scrollbar {
    background: transparent; /* Optional: just make scrollbar invisible */
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: $accentColor;
  }

  .swipeDiv {
    width: 100%;
    height: 10%;

    @keyframes swipe {
      from {
        transform: rotate(35deg);
      }
      to {
        transform: rotate(-35deg);
      }
    }
    .swipeIcon {
      position: relative;
      transform: scale(1.3);
      color: $mainColor;
      animation: swipe 1s;
      animation-direction: alternate;
      animation-iteration-count: infinite;
    }
  }
}
.modal-content {
  background-color: $secondColor !important;
  color: $mainColor !important;
}

.closeBtn {
  background-color: $accentColor !important;
  border: none !important;
  border-radius: 50px !important;
  box-shadow: -2px -5px 7px #363b44, -2px 5px 7px #14171a !important;
}

.btn-close {
  border-radius: 50px !important;
  box-shadow: -2px -5px 7px #363b44, -2px 5px 7px #14171a !important;
}

.date,
.location {
  font-weight: 100;
  font-size: 14px;
}

.modal-body {
  max-height: 70vh;

  h6 {
    color: $accentColor;
  }
}
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-thumb {
  background-color: $accentColor;
  border-radius: 50px; /* roundness of the scroll thumb */
}

@media (min-width: 1200px) {
  #experience .swipeDiv {
    display: none;
  }
  .jobs {
    cursor: default !important;
  }
  .experience .jobs {
    justify-content: center;
  }
}
