@import "../../global.scss";

.contact {
  #form-container {
    height: 70%;
    .row {
      height: 100%;
      justify-content: center;
      padding-bottom: 30px;
      .form-div {
        margin-top: 15px;
        max-height: 60vh;
        min-width: 300px;
        border-radius: 20px;
        box-shadow: -2px -5px 20px #363b44, 0px 5px 20px #14171a;
        padding-top: 70px;
        padding-bottom: 70px;
        padding: 30px;
        position: relative;
        .btnContainer {
          justify-content: center;
          display: flex;

          .detailsBtn {
            background-color: $accentColor;
            border: none;
            border-radius: 50px;
            box-shadow: -2px -5px 7px #363b44, -2px 5px 7px #14171a;
            position: absolute;
            bottom: 25px;
          }
        }
      }
    }
  }
  #contact-container {
    height: 15%;
    .row {
      justify-content: center;
      padding-bottom: 10px;

      .contact-div {
        align-items: center;
        text-align: center;
        display: flex;
        border-radius: 20px;
        box-shadow: -2px -5px 20px #363b44, 0px 5px 20px #14171a;
        margin-top: 5px;
        .col-3 {
          padding: 10px;
          .social-icon {
            height: 40px;
            border-radius: 50%;
            box-shadow: -2px -5px 20px #363b44, 0px 5px 20px #14171a;
          }
        }
      }
    }
  }
}
