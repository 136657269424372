@import "../../global.scss";

.projet {
  // background-color: lightcoral;
  background-color: $secondColor;
  justify-content: center;
  text-align: center;

  .applications {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    height: 75%;
    padding-right: 9.5%;
    cursor: grab;

    .application {
      margin-top: 20px;
      margin-left: 9.5%;
      max-height: 60vh;
      min-width: 300px;
      max-width: 350px;
      background-color: $secondColor;
      color: $mainColor;
      border-radius: 20px;
      box-shadow: -2px -5px 20px #363b44, 0px 5px 20px #14171a;
      padding-top: 70px;
      padding-bottom: 70px;
      padding: 30px;
      position: relative;

      .row {
        margin-bottom: 10px;
      }

      .logoApp {
        height: 50px;
        border-radius: 10px;
      }
      h3 {
        position: absolute;
        margin-top: 10px;
      }
      .category {
        color: $accentColor;
      }

      .resume {
        margin-top: 20px;
      }

      .techno {
        font-size: 12px;
        font-weight: 100;
        margin: 10px 0 0 0;
      }

      .btnContainer {
        justify-content: center;
        display: flex;
        padding: 15px;

        .detailsBtn {
          background-color: $accentColor;
          border: none;
          border-radius: 50px;
          box-shadow: -2px -5px 7px #363b44, -2px 5px 7px #14171a;
          bottom: 25px;
          position: absolute;

          text-decoration: none;
          color: $mainColor;
          width: 60%;
          padding: 3px;
        }
      }

      div ul {
        padding: 0;
      }
    }
  }
  ::-webkit-scrollbar {
    background: transparent; /* Optional: just make scrollbar invisible */
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: $accentColor;
  }

  .swipeDiv {
    width: 100%;
    height: 10%;

    @keyframes swipe {
      from {
        transform: rotate(35deg);
      }
      to {
        transform: rotate(-35deg);
      }
    }
    .swipeIcon {
      position: relative;
      transform: scale(1.3);
      color: $mainColor;
      animation: swipe 1s;
      animation-direction: alternate;
      animation-iteration-count: infinite;
    }
  }
}

@media (min-width: 2139px) {
  .applications {
    cursor: default !important;
  }
  #projet .swipeDiv {
    display: none;
  }
}
