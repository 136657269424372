@import "global.scss";

* {
  font-family: Montserrat, "Dosis", sans-serif;
  margin: 0;
  overflow-x: hidden;
}

.app {
  height: 100vh;
  color: $mainColor;
  padding: 0;
  margin: 0;
  background-color: $secondColor;
  overflow-y: hidden;

  .sections {
    width: 100%;
    height: 90vh;
    background-color: $secondColor;
    position: relative;
    top: 7vh;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scrollbar-width: none; // for firefox
    &::-webkit-scrollbar {
      display: none;
    }

    > * {
      width: 100vw;
      height: 90vh;
      scroll-snap-align: start;
    }
    svg {
      filter: drop-shadow(1.5px 3.5px 0.5px rgb(26, 26, 26));
    }

    .cardTitle {
      font-size: 24px;
    }

    .extraMargin {
      margin-left: 20px;
    }
  }
  .bottom {
    overflow: visible;
    height: 8vh;
    bottom: 2.5vh;
    position: fixed;
    width: 100%;
  }
  .top {
    position: fixed;
    width: 100%;
    overflow: hidden;
    height: 5vh;
  }
}

html body {
  user-select: none;
  background-color: $secondColor;
}
