@import "../../global.scss";

.left {
  position: fixed;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: 100%;

  .logo {
    font-family: "Charm", cursive;
    font-size: 28px;
    font-weight: 700;
    text-decoration: none;
    color: $accentColor;
    margin-right: 40px;

    img {
      height: 150px;
      top: -40px;
      left: 5px;
      position: absolute;
    }
  }
}

@media screen and (max-width: 500px) {
  .left .logo img {
    height: 100px !important;
    top: -20px !important;
  }
}
